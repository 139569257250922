(function(){

    let swiper_condominium = undefined;
    let swiper_condominiumElement = document.getElementsByClassName('condominium-slider')[0];
    let swiper_condominiumCard = document.getElementsByClassName('condominium-swiper-slide');
    let swiper_condominiumLength = swiper_condominiumCard.length;

    let swiper_cases = undefined;
    let swiper_casesElement = document.getElementsByClassName('cases-slider')[0];
    let swiper_casesCard = document.getElementsByClassName('cases-slide');
    let swiper_casesLength = swiper_casesCard.length;

    let swiper_cases_flag;

    let option_condominium = {
        loop: true,
        autoplay: {
            disableOnInteraction: false,
            delay: 5000,
        },
        slidesPerView: 1,
        speed: 800,
        loopAdditionalSlides: 2,
        pagination: {
            el: '.condominium-slider-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.condominium-slider-next',
            prevEl: '.condominium-slider-prev',
        },
    };

    let option_cases = {
        loop: true,
        autoplay: {
            disableOnInteraction: false,
            delay: 5000,
        },
        slidesPerView: 1,
        speed: 800,
        loopAdditionalSlides: 2,
        watchOverflow: true,
        pagination: {
            el: '.cases-slider_pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.cases-slider_next',
            prevEl: '.cases-slider_prev',
        },
    };

    let option_cases_sp = {
        loop: true,
        autoplay: {
            disableOnInteraction: false,
            delay: 5000,
        },
        slidesPerView: 1,
        speed: 800,
        loopAdditionalSlides: 2,
        pagination: {
            el: '.cases-slider_pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.cases-slider_next',
            prevEl: '.cases-slider_prev',
        },
    };

    function initSwiper() {
        var screenWidth = window.innerWidth;
        if(swiper_condominiumElement){
            if (swiper_condominiumLength <= 1) {
                swiper_condominiumElement.classList.add("no-slider");
            } else{
                swiper_condominium = new Swiper('.condominium-slider .swiper-container', option_condominium);
            }
        }
        if(swiper_casesElement){

            if(screenWidth < 768 && swiper_cases_flag !== 'sp') {
                swiper_on(swiper_cases);
                if (swiper_casesLength <= 1) {
                    swiper_casesElement.classList.add("no-slider");
                } else{
                    swiper_cases = new Swiper('.cases-slider .swiper-container', option_cases_sp);
                }
                swiper_cases_flag = 'sp';
            }else if(screenWidth > 767 && swiper_cases_flag !== 'pc'){
                swiper_on(swiper_cases);
                if (swiper_casesLength <= 3) {
                    swiper_casesElement.classList.add("no-slider");
                } else{
                    swiper_cases = new Swiper('.cases-slider .swiper-container', option_cases);
                }
                swiper_cases_flag = 'pc';
            }
        }

    }

    function swiper_on(obj_) {
        if(obj_ != undefined){
            obj_.destroy();
            obj_ = undefined;
        }else if(obj_ == undefined){
            return;
        }
    }

    initSwiper();
    window.addEventListener('resize',initSwiper);

})();